// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Mihir's Portfolio",
  description:
    "Portfolio website, to showcase skills and side projects of student Mihir Deo!",
  og: {
    title: "Mihir's Portfolio",
    type: "website",
    url: "https://mihirdeo16.com",
  },
};

//Home Page
const greeting = {
  title: "Mihir Deo",
  logo_name: "Mihir",
  nickname: "MD",
  subTitle: "A passionate individual about technology 🧑🏻‍💻 and analytics 🧐. A engineer by heart 👨‍🔧, love to build tools and application related to machine learning and AI. ",

  resumeLink:
    "https://docs.google.com/document/d/e/2PACX-1vSkgwmLQqKgXS3odAPL_syums8DfHghAsr0QjVSdqWD29Jo-IDGPvsRKwfCvrQW6aq_dS86CXbXFsPR/pub",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/mihirdeo16/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Github",
    link: "https://github.com/mihirdeo16",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Gmail",
    link: "mihirdeo16@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/mihirdeo16",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Kaggle",
    link: "https://www.kaggle.com/rihim421",
    fontAwesomeIcon: "fab fa-kaggle", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Hackerank",
    link: "https://www.hackerrank.com/mihir_deo",
    fontAwesomeIcon: "fab fa-hackerrank", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "🏗 Develop machine learning models and applications to help businesses make decisions and become more efficient.",
        " 📈 Create data visualization dashboards to let stakeholders and managers know about the data and gain insight.",
        " 🛠 Build applications and tools to assist developers and data scientists in reducing their development lifecycle.",
        "🧠 Solve the complex problems and architect the solution using open-source tools.",
        "⚡ Fun fact about me, I love to do  🔭 star gazing, and 🏊🏻‍♂️ enjoy swimming."
        // "⚡ Python developer work on API development, data visulization dashbord, also familar with  Docker building building and UNIX system.",
        // "⚡ Work on Google cloud Platform and different database flavour such as MySQL, Mongodb, BigQuery, BigTable, PostgreSQL, Cassandra. ",
        // "⚡ Working with Time series, Tabular and Computer Vision projects, Machine Learining model building",
        // "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis statstical analysis",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "logos-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "R",
          fontAwesomeClassname: "simple-icons:r",
          style: {
            backgroundColor: "transparent",
            color: "#276DC3",
          },
        },
        {
          skillName: "SQL",
          fontAwesomeClassname: "vscode-icons:file-type-sql",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "simple-icons:pytorch",
          style: {
            backgroundColor: "transparent",
            color: "#EE4C2C",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            backgroundColor: "transparent",
            color: "#092E20",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Bash",
          fontAwesomeClassname: "logos:bash-icon",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "logos:git-icon",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub-Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#2088FF",
          },
        },

        {
          skillName: "Linux",
          fontAwesomeClassname: "fa-brands:linux",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "logos:google-cloud",
          style: {
            color: "#4285F4",
          },
        },
      ],
    },
    // {
    //   title: "Data Science & AI",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
    //     "⚡ Experience of working with Computer Vision and NLP projects",
    //     "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
    //   ],
    //   softwareSkills: [
    //
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //      {
    //        skillName: "PyTorch",
    //        fontAwesomeClassname: "logos-pytorch",
    //        style: {
    //          backgroundColor: "transparent",
    //      },
    //      },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "Full Stack Development",
    //   fileName: "FullStackImg",
    //   skills: [
    //     "⚡ Building resposive website front end using React-Redux",
    //     "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
    //     "⚡ Creating application backend in Node, Express & Flask",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "HTML5",
    //       fontAwesomeClassname: "simple-icons:html5",
    //       style: {
    //         color: "#E34F26",
    //       },
    //     },
    //     {
    //       skillName: "CSS3",
    //       fontAwesomeClassname: "fa-css3",
    //       style: {
    //         color: "#1572B6",
    //       },
    //     },
    //      {
    //        skillName: "Sass",
    //        fontAwesomeClassname: "simple-icons:sass",
    //        style: {
    //          color: "#CC6699",
    //        },
    //      },
    //     {
    //       skillName: "JavaScript",
    //       fontAwesomeClassname: "simple-icons:javascript",
    //       style: {
    //         backgroundColor: "#000000",
    //         color: "#F7DF1E",
    //       },
    //     },
    //     // {
    //     //   skillName: "ReactJS",
    //     //   fontAwesomeClassname: "simple-icons:react",
    //     //   style: {
    //     //     color: "#61DAFB",
    //     //   },
    //     // },
    //     // {
    //     //   skillName: "NodeJS",
    //     //   fontAwesomeClassname: "simple-icons:node-dot-js",
    //     //   style: {
    //     //     color: "#339933",
    //     //   },
    //     // },
    //     // {
    //     //   skillName: "NPM",
    //     //   fontAwesomeClassname: "simple-icons:npm",
    //     //   style: {
    //     //     color: "#CB3837",
    //     //   },
    //     // },
    //     // {
    //     //   skillName: "Yarn",
    //     //   fontAwesomeClassname: "simple-icons:yarn",
    //     //   style: {
    //     //     color: "#2C8EBB",
    //     //   },
    //     // },
    //     // {
    //     //   skillName: "Gatsby",
    //     //   fontAwesomeClassname: "simple-icons:gatsby",
    //     //   style: {
    //     //     color: "#663399",
    //     //   },
    //     // },
    //     // {
    //     //   skillName: "Flutter",
    //     //   fontAwesomeClassname: "simple-icons:flutter",
    //     //   style: {
    //     //     color: "#02569B",
    //     //   },
    //     // },
    //   ],
    // },
    // {
    //   title: "Cloud Infra-Architecture",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Experience working on multiple cloud platforms",
    //     "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
    //     "⚡ Deploying deep learning models on cloud to use on mobile devices",
    //     "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "GCP",
    //       fontAwesomeClassname: "simple-icons:googlecloud",
    //       style: {
    //         color: "#4285F4",
    //       },
    //     },
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     // {
    //     //   skillName: "Azure",
    //     //   fontAwesomeClassname: "simple-icons:microsoftazure",
    //     //   style: {
    //     //     color: "#0089D6",
    //     //   },
    //     // },
    //     // {
    //     //   skillName: "Firebase",
    //     //   fontAwesomeClassname: "simple-icons:firebase",
    //     //   style: {
    //     //     color: "#FFCA28",
    //     //   },
    //     // },
    //     {
    //       skillName: "PostgreSQL",
    //       fontAwesomeClassname: "simple-icons:postgresql",
    //       style: {
    //         color: "#336791",
    //       },
    //     },
    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     // {
    //     //   skillName: "Kubernetes",
    //     //   fontAwesomeClassname: "simple-icons:kubernetes",
    //     //   style: {
    //     //     color: "#326CE5",
    //     //   },
    //     // },
    //   ],
    // },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/mihir_deo",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/rihim421",
    },
    {
      siteName: "qwiklabs",
      iconifyClassname: "simple-icons:qwiklabs",
      style: {
        color: "#ffff00",
      },
      profileLink:
        "https://google.qwiklabs.com/public_profiles/c3c56a83-f833-4fe9-8e56-d292800891d3",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Master in Analytics",
      subtitle: "Northeastern University (College of Professional Studies)",
      logo_path: "Northeastern_University_seal.svg",
      alt_name: "Northeastern University",
      duration: "2022 - 2024",
      descriptions: [
        "Major in Applied Machine Intelligence.",
        "Relevant coursework: Analytics, Data Mining, Big Data, Artificial Intelligence, Predictive Analytics, and Visualization, Statistics, Applied AI.",
      ],
      website_link: "https://www.northeastern.edu/",
    },

    {
      title: "Bachelors of Engineering , Electronic",
      subtitle:
        "Pune University (Maharashtra Institute of Technology, college)",
      logo_path: "mit_pune.png",
      alt_name: "MIT Pune",
      duration: "2016 - 2020",
      descriptions: [
        "Minor in Artificial Intelligence.",
        "Improved IoT device communication efficiency using statistical modeling and reinforcement learning.",
        "Relevant coursework: Artificial Intelligence,  Machine Learning, Object Oriented Programming, Data Structures and Algorithms",
      ],
      website_link: "https://mitwpu.edu.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Integrate with Machine Learning APIs",
      subtitle: "- Qwiklabs",
      logo_path: "ml_api.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/c3c56a83-f833-4fe9-8e56-d292800891d3/badges/628293",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Perform Foundational Data, ML, and AI Tasks in Google Cloud",
      subtitle: "- Qwiklabs",
      logo_path: "PF_Data_ML_onGCP.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/c3c56a83-f833-4fe9-8e56-d292800891d3/badges/624129",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Perform Foundational Data, ML, and AI Tasks in Google Cloud",
      subtitle: "- Qwiklabs",
      logo_path: "PF_Data_ML_onGCP.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/c3c56a83-f833-4fe9-8e56-d292800891d3/badges/624129",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Data Engineering with Google Cloud",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/certificate/76TVLGASNF3T",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Industrial IoT on Google Cloud Platform ",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://coursera.org/share/0f2b3abc1f9a801171bee26c3a5c6112",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Linux for Developers",
      subtitle: "Linux Foundation",
      logo_path: "linux_foundation.png",
      certificate_link:
        "https://coursera.org/share/2345164f93872e4814be3b912279ae48",
      alt_name: "Linux Foundation",
      color_code: "#1F70C199",
    },
    {
      title: "Linux Tools for Developers",
      subtitle: "Linux Foundation",
      logo_path: "linux_foundation.png",
      certificate_link:
        "https://coursera.org/share/d09109cb8cd8325af8ad4e57647b87be",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    // #### Big Data
    {
      title: "Introduction to Big Data",
      subtitle: "- Ilkay Altintas",
      logo_path: "uc_sandiago.jpeg",
      certificate_link:
        "https://coursera.org/share/8619fde545e137be104e697816b46ec7",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data Modeling and Management Systems",
      subtitle: "- Ilkay Altintas",
      logo_path: "uc_sandiago.jpeg",
      certificate_link:
        "https://coursera.org/share/205f616939761e69d103abc85dba358d",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data Integration and Processing",
      subtitle: "- Ilkay Altintas",
      logo_path: "uc_sandiago.jpeg",
      certificate_link:
        "https://coursera.org/share/734ea1440e476bc2660af8b8be36297d",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work Experience & Internship",
  description:
    "I have two years of experience in software development and have extensively work in AI & Machine learning domain with python ecosystem tools.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Data Scientist, Python Developer",
          company: "Fluid AI",
          company_url: "https://fluid.ai/",
          logo_path: "fluid_ai.png",
          duration: "April 2021 - Augest 2022",
          location: "Mumbai, Maharashtra",
          description:
            "I have worked on building models and serving in production for fraud detection, product-recommendation and failure forecast. Work on building a pipeline to serve the prediction in a scalable manner. Building analytic dashboard and custom application using Django. building AutoML framework.",
          color: "#0879bf",
        },
        {
          title: "Full-Stack Software Enginner",
          company: "MiniOrange",
          company_url: "https://www.miniorange.com/",
          logo_path: "miniorange.png",
          duration: "August 2020 - October2020",
          location: "Pune, Maharashtra",
          description:
            "I have work as a full stack developer for Identity Access Management development of REST API and web. Work on language and technologies such as JavaScript, CSS, PHP and Postman",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Data Science Computer Vision Intern",
          company: "Shyena Tech Yarns Pvt Ltd",
          company_url: "https://shyenatechyarns.com/",
          logo_path: "logo_shyena_tech_yarns.png",
          duration: "March 2021 - April 2021",
          location: "Pune, Maharashtra",
          description:
            "",
          color: "#ee3c26",
        },
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Explore ML Facilitator",
    //       company: "Google",
    //       company_url: "https://about.google/",
    //       logo_path: "google_logo.png",
    //       duration: "June 2019 - April 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //       color: "#4285F4",
    //     },
  ],
};

// project's Page
const projectsHeader = {
  title: "Projects",
  description:
    "I love to build API servers, containerized applications and machine learning models using python eco-system. Also maintain a open-source python library. I have broad experience with different MLOps and DevOps tools.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "🙋 About me & 📬 reach me out!",
    profile_image_path: "profile_2.png",
    description: "👋 I am Mihir, a data scientist, and tech enthusiast. I enjoy talking about AI&ML 📢, creating open source software 🧑‍💻, and love 🏊🏻‍♂️ swimming. Please get in touch with me if you need a partner for a project or to brainstorm. If you have any questions or would like to speak, you can connect with me through these platforms, don’t hesitate to get in touch with me! 👇",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://ashutoshhathidara.wordpress.com",
    avatar_image_path: "blogs_image.svg",
  },
  phoneSection: {
    title: "Feel free to contact me !",
    subtitle: "👇",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};