import React from "react";
import ProjectLanguages from "../../components/projectLanguages/ProjectLanguages";
import "./GithubRepoCard.css";
import { Fade } from "react-reveal";

export default function GithubRepoCard({ repo, theme }) {
  function openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <div
          className="repo-card-div"
          key={repo.id}
          onClick={() => openRepoinNewTab(repo.url)}
          style={{ backgroundColor: theme.highlight }}
        >
          <div className="repo-name-div">
            <svg
              aria-hidden="true"
              className="octicon repo-svg"
              height="16"
              role="img"
              viewBox="0 0 12 16"
              width="12"
            >
              <path
                fill-rule="evenodd"
                d="M4 9H3V8h1v1zm0-3H3v1h1V6zm0-2H3v1h1V4zm0-2H3v1h1V2zm8-1v12c0 .55-.45 1-1 1H6v2l-1.5-1.5L3 16v-2H1c-.55 0-1-.45-1-1V1c0-.55.45-1 1-1h10c.55 0 1 .45 1 1zm-1 10H1v2h2v-1h3v1h5v-2zm0-10H2v9h9V1z"
              ></path>
            </svg>
            <p className="repo-name" style={{ color: theme.text }}>
              {repo.name}
            </p>
          </div>
          <p className="repo-description" style={{ color: theme.text }}>
            {repo.description}
          </p>
          <div className="repo-details">
            {/* <p
              className="repo-creation-date subTitle"
              style={{ color: theme.secondaryText }}
            >
              Created on {repo.createdAt.split("T")[0]}
            </p> */}
            {/* <ProjectLanguages
              className="repo-languages"
              logos={repo.languages}
            /> */}
          </div>
        </div>
      </Fade>
    </div>
  );
}
// function svg_selector(key){
//   if (key in[1,3,5,7,9]){
//     return (<svg
//     aria-hidden="true"
//     className="cib-jupyter"
//     height="1.2em"
//     role="img"
//     viewBox="0 0 32 32"
//     width="1.2em"
//   >
//     <path
//       fill-rule="#626262"
//       d="M16.265 24.136c-5.068 0-9.599-1.871-12-4.537c.932 2.401 2.537 4.537 4.667 6c2.136 1.469 4.667 2.265 7.333 2.265s5.204-.796 7.333-2.265c2.136-1.463 3.735-3.599 4.667-6c-2.401 2.667-6.932 4.537-12 4.537zm0-17.605c5.068 0 9.599 1.871 12 4.537c-.932-2.401-2.531-4.537-4.667-6c-2.265-1.599-4.796-2.401-7.333-2.401c-2.667 0-5.197.803-7.333 2.265c-2.129 1.599-3.735 3.599-4.667 6.136c2.401-2.803 6.803-4.537 12-4.537zM25.599.136c-2.489 0-2.489 3.728 0 3.728s2.489-3.728 0-3.728zM6.803 27.068a2.397 2.397 0 1 0 0 4.796a2.395 2.395 0 0 0 2.395-2.395a2.396 2.396 0 0 0-2.395-2.401zm-2-24c-1.781 0-1.781 2.667 0 2.667c1.776 0 1.776-2.667 0-2.667z"
//     ></path>
//   </svg>)
//   }
//   else{
//     return ()
//   }
// }
